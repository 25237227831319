import React, { useEffect } from "react"

import { Layout } from "../components/Layout"
import Seo from "../components/SEO"
import {ITranslationData} from "../utils/types";

const useChatForm = false;

// the following is used only if Chat Form enabled
const FW_ID = 72000002802;
const onFreshDesk = () => {
    // @ts-ignore
    console.log("FreshDesk loaded", typeof window.FreshworksWidget);
    // @ts-ignore
    let FreshworksWidget = window.FreshworksWidget;
    if (typeof FreshworksWidget != "function") {
        console.warn("Freshworks Widget not loaded");
        return
    }
    FreshworksWidget('open', 'ticketForm');
}
const waitFreshDesk = () => {
    setTimeout(onFreshDesk, 300);
}

// and this is only for Contact Form
const formUrl = "https://emerald-help.freshdesk.com/widgets/feedback_widget/new?widgetType=embedded&searchArea=no"
    + "&formTitle="+encodeURIComponent("Support Form")
    + "&submitThanks="+encodeURIComponent("Thank you for reaching out to us. An Emerald representative will contact you as soon as possible.")
    + "&submitTitle="+encodeURIComponent("Submit Ticket")
;

const Page = () => {
    useEffect(() => {

        setTimeout(() => {
            // @ts-ignore
            window.fwSettings={ 'widget_id': FW_ID };
            const script = document.createElement('script');
            script.async = true;
            if (useChatForm) {
                script.src = `https://widget.freshworks.com/widgets/${FW_ID}.js`;
                script.addEventListener('load', waitFreshDesk);
            } else {
                script.src = `https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js`;
            }
            document.body.appendChild(script);
        }, 500)

        return () => {}
    })
    let translation: ITranslationData = {
        lang: "en-us",
        url: "/support",
        translations: []
    };
    return (
        <Layout translationData={translation}>
            <div className="support flex md:mt-16 mb-32 px-[5%] justify-center">
                <div className="w-full max-w-screen-md">
                    <h1 className="font-bold text-3xl md:text-4xl">Contact Support</h1>
                    <div className="mt-8">
                        <div id="support-form" className="w-full">
                            <iframe title="Feedback Form"
                                    className="freshwidget-embedded-form"
                                    id="freshwidget-embedded-form"
                                    src={formUrl}
                                    scrolling="no" height="500px" width="100%"
                                    frameBorder="0" >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Page
